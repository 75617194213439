import {
	Controller,
	useFormContext,
	type RegisterOptions,
} from 'react-hook-form';
import dynamic from 'next/dynamic';
const PhoneInput = dynamic(() => import('react-phone-input-2'), {
	ssr: false,
	loading: () => <input />,
});
import {useEffect, useState} from 'react';
import {useTimezoneInfo} from '@halp/util';
import {useAsyncCss} from '@halp/ui';
import {useI18n} from '../../../i18n';
import {FormField} from '../FormField';
import style from './FormPhoneField.module.css';
import type {CountryCode} from 'libphonenumber-js';

interface Props {
	name: string;
	label?: string;
	placeholder?: string;
	timezone?: string;
	optional?: boolean;
	onChange?: (phone: string | undefined) => void;
	rules?: RegisterOptions;
	className?: string;
}

export function FormPhoneField({
	name,
	label,
	placeholder,
	timezone,
	optional,
	onChange,
	rules,
	className,
}: Props) {
	const {t} = useI18n();
	const initialCountry = useTimezoneInfo(timezone)?.countryCode;
	const [countryCode, setCountryCode] = useState<CountryCode | undefined>(
		initialCountry?.toUpperCase() as CountryCode,
	);

	useAsyncCss('/css/react-phone-input-2-bootstrap.css');

	useEffect(() => {
		setCountryCode(initialCountry?.toUpperCase() as CountryCode);
	}, [initialCountry, setCountryCode]);

	async function validatePhone(value: string) {
		const {isValidNumber} = await import('libphonenumber-js');
		return isValidNumber(value, countryCode);
	}

	const {register} = useFormContext();

	const {ref} = register(name);

	return (
		<FormField
			name={name}
			optional={optional}
			label={label}
			rules={rules}
			className={className}
		>
			<Controller
				name={name}
				rules={{
					required: t('validation.mixed.required'),
					validate: async (value) => {
						return (await validatePhone(value)) || t('validation.phone.valid');
					},
				}}
				render={({
					field: {onChange: fieldChange, onBlur, value: fieldValue},
				}) => {
					function handleChange(
						value: string,
						country?: {countryCode?: string},
					) {
						setCountryCode(country?.countryCode?.toUpperCase() as CountryCode);
						fieldChange(value ?? null);
						if (onChange) {
							onChange(value);
						}
					}

					return (
						<PhoneInput
							/* @ts-expect-error bad type */
							ref={ref}
							isValid
							value={fieldValue}
							country={initialCountry?.toLowerCase()}
							onChange={handleChange}
							placeholder={placeholder ?? t('phone')}
							onBlur={onBlur}
							inputClass={style.PhoneInput}
						/>
					);
				}}
			/>
		</FormField>
	);
}
