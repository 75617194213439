export function buildQueryString(query: {
	[key: string]: string | string[] | undefined | null;
}) {
	const definedQueries = Object.keys(query).reduce(
		(acc, key) =>
			query[key] === undefined || query[key] === null
				? {...acc}
				: {...acc, [key]: query[key]},
		{},
	);

	return new URLSearchParams(definedQueries).toString();
}
