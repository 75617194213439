import {useEffect, useState} from 'react';
import type {TimeZone, TimeZoneName} from '@vvo/tzdb';

export function useTimezoneInfo(tztofind?: TimeZoneName | null) {
	const [tz, setTZ] = useState<TimeZone | undefined>();

	useEffect(() => {
		const getTimeZones = async (search: string) => {
			const tz = (await import('@vvo/tzdb')).getTimeZones().find((timezone) => {
				return timezone.name === search;
			});
			setTZ(tz);
		};

		if (tztofind) {
			getTimeZones(tztofind);
		} else {
			setTZ(undefined);
		}
	}, [tztofind, setTZ]);

	return tz;
}
